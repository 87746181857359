@import '../../../../../resources/variables';

.course_sesiones_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .course_sesiones {
    display: flex;
    width: 100%;
    div {
      flex: 1;
    }
  }
  .btn_back {
    margin-top: 15px;
    button {
      background-color: #ffc200;
      border: 0;
      padding: 8px 25px 8px 25px;
      font-weight: bold;
      display: flex;
      font-size: 12px;
      align-items: center;
      gap: 15px;
    }
  }

  .list_sesiones {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 3px;
    padding: 8px;
    li {
      width: 100%;
      button {
        width: 100%;
        text-align: start;
        background-color: $primary-color;
        color: white;
        border: 0;
        padding: 6px 12px;
        &.active {
          background-color: white !important;
          border: solid $primary-color 1px !important;
          color: $primary-color !important;
        }
      }
      button:hover {
        background-color: $primary-color;
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .course_sesiones_container {
    margin-top: 46px;
    .btn_back {
      button {
      }
    }

    .list_sesiones {
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
    }
    .course_sesiones {
      flex-direction: column-reverse;
    }
  }
}
