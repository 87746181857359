.bo_button {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 0;
  width: 100%;
}

.bo_button:hover {
  cursor: pointer;
}

.bo_button_nav_item {
  padding: 13px 30px 13px 24px;
  font-size: 17px;
  .div_icon {
    margin-right: 20px !important;
    display: flex;
  }
}

.bo_button_logout {
  padding: 7px 30px 7px 18px;
  font-size: 17px;
  .div_icon {
    margin-right: 20px !important;
  }
}
