@import '../../../../resources/variables';

.content_actions_container {
  .desktop_version {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .mobile_version {
    display: none;
  }
}

@media screen and (max-width: $mobile) {
  .content_actions_container {
    .desktop_version {
      display: none;
    }
    .mobile_version {
      display: flex;

      .input_mobile {
        width: 40%;
      }
    }
  }
}
