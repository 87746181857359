@import '../../../../../resources/variables';

.program_template_container {
  .program_header {
    position: relative;

    .program_background {
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .linear_gradient {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      /* background-image: linear-gradient(45deg, #011754, green); */
      background-color: #011754;
      opacity: 0.7;
    }

    .program_header_content {
      display: grid;
      grid-template-columns: 8fr 8fr;
      gap: 20px;

      .program_header_content_image {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .program_header_image {
        width: 100%;
      }
      .program_content {
        .video_container_mobile {
          display: none;
          margin-top: 15px;
          img {
            width: 100%;
          }
        }
      }

      .btn_back_to_zoom {
        margin-top: 15px;
        button {
          background-color: #ffc200;
          border: 0;
          padding: 8px 25px 8px 25px;
          font-weight: bold;
          display: flex;
          font-size: 12px;
          align-items: center;
          gap: 15px;
        }
      }

      .curso_info_header {
        color: white;
        .curso_titulo {
          font-weight: bold;
          font-size: 25px;
          margin-top: 10px;
        }

        .curso_descripcion {
        }

        .calificacion {
          display: flex;
          align-items: center;
          gap: 10px;
          p {
            margin: 0;
          }
          .value {
            color: #ffc22b;
          }
          .stars {
            display: flex;
            justify-content: space-evenly;
            gap: 5px;
            margin-right: 20px;
          }
        }
      }

      .curso_info_bottom {
        display: grid;
        gap: 20px;
        color: white;
        // &.online_ind {
        //   grid-template-columns: 8fr;
        // }
        &.zoom_ind {
          grid-template-columns: 8fr 8fr;
        }
        .curso_info_lista {
          display: flex;
          flex-direction: column;
          list-style-type: none;
          gap: 12px;
          font-size: 15px;
          margin-left: 0;
          padding-left: 0;
          li {
            p {
              margin: 0;
              display: flex;
              gap: 15px;
              align-items: center;
            }
          }

          .space {
            background-color: rgba(255, 255, 255, 0.3);
            height: 1px;
            width: 210px;
          }
        }

        .about_teacher {
          .about_bottom {
            display: flex;

            align-items: flex-start;
            gap: 20px;
            width: 100%;
            // margin-top: 35px;
            &.online_ind {
              flex-direction: row;
            }
            &.zoom_ind {
              flex-direction: column;
            }
            .proffessor_photo {
              height: 85px;
              border-radius: 50%;
            }
            .proffessor_name {
              color: #ffc200;
              font-weight: bold;
            }
            .proffesor_info {
              font-size: 14px;
            }
          }
        }

        .curso_info_bottom_title {
          font-weight: bold;
          font-size: 18px;
        }
      }

      .video_container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .program_content_container {
    .ul_routes {
      ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin: 0;

        li {
          background-color: #ffc200;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          button {
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            width: 100%;
            padding: 16px 0;
            font-size: 14px;
            font-weight: bold;
            position: relative;

            .arrow {
            }

            .materialsActive,
            .liveActive,
            .recordedActive,
            .finalProjectActive,
            .aboutActive {
              position: absolute;
              bottom: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 12px solid white;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .program_template_container {
    padding-top: 56px;
    .program_header {
      .program_header_content {
        grid-template-columns: 1fr;
        .program_content {
          .video_container_mobile {
            display: flex;
            margin-top: 15px;
            img {
              width: 100%;
            }
          }
        }
        .curso_info_bottom {
          grid-template-columns: 1fr;
          .about_teacher {
            .about_bottom {
              flex-direction: row;
            }
          }
        }
        .video_container_up {
          padding-top: 20px;
        }
        .video_container_down {
          display: none;
        }
        // .video_container {
        //   display: none;
        // }
      }
    }
  }
  .program_content_container {
    .ul_routes {
      ul {
        overflow-x: scroll;
        li {
          button {
            width: 150px !important;
          }
        }
      }
      ul::-webkit-scrollbar {
        width: 1px;
        height: 5px;
      }

      ul::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      ul::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        outline: 1px solid $primary-color;
      }
    }
  }
}

@media screen and (min-width: $mobile) {
  .video_container_up {
    display: none;
  }
  .video_container_down {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
