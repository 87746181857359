$primary-color: #011754;
$secondary-color: #ffc200;
$color-grey: #9b9b9b;

$tablet: 1200px;
$mobile: 600px;

$width-menu: 360px;

$z-index-menu: 99999;

@media screen and (max-width: 1200px) {
  $width-menu: 300px;
}
