.presentation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  .btn-choose-upload {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  textarea {
    border-radius: 16px;
    padding: 12px;
    &:focus {
      border: solid gray 1px;
      outline-offset: 0px !important;
      outline: none !important;
    }
  }
}
