@import '../../../../resources/variables.scss';

.programs_container {
  margin: 20px auto;
  .program_container_flex {
    margin-bottom: 50px;
    .program_desktop {
      .programs_grid {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        column-gap: 20px;
      }
    }
    // .program_mobile {
    //   display: none;
    // }
    .program_without_results {
      text-align: center;
      flex-direction: column;
    }
  }
}

@media (max-width: $mobile) {
  .programs_container {
    .program_container_flex {
      .program_desktop {
        display: block;
      }
      // .program_mobile {
      //   display: block;
      // }
    }
  }
}
