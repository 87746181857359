@import '../../../../resources/variables';

.user_profile_template_container {
  height: 100%;

  .user_profile_flex {
    display: flex;
    gap: 30px;
    .aboutme_container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 420px;

      .aboutme_photo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        align-items: center;
        button {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
    .account_container {
      width: 100%;
    }
  }

  .profile_titles {
    font-size: 24px;
    font-weight: bold;
  }

  .profile_subtitles {
    color: #979a9a;
    font-weight: bold;
  }

  .account_container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .save_changes {
      button {
        color: black;
        &.active {
          background-color: #ffc200;
          color: white;
        }
        float: right;
        border: 0;
        padding: 20px 30px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .areas_flex_columns_20 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  p {
    margin: 0;
  }
}

@media screen and (max-width: $mobile) {
  .user_profile_template_container {
    height: 100%;

    .user_profile_flex {
      display: flex;
      flex-direction: column;

      .aboutme_container {
        width: 100%;
      }
      .account_container {
        .desktop_version {
          display: none;
        }
      }
    }
  }
}
