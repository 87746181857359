@import '../../../../resources/base';

.navbar_container {
  ul {
    list-style-type: none;
    padding: 0;
    li {
      margin: 2px 0;
      a {
        font-size: 20px !important;
      }
    }
  }
}

.certificateActive,
.programasActive,
.offlineActive {
  background-color: white;
  color: $primary-color !important;
}

@media screen and (max-width: $mobile) {
  .navbar_container {
    ul {
      li {
        a {
          button {
            padding: 10px 0px 10px 25px;
          }
        }
      }
    }
  }
}
