@import '../../../resources/variables.scss';

.certificates_container {
  // margin: 20px auto;
  h1 {
    // font-family: 'Lato';
    font-size: 28px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #011754;
    font-weight: bold;
  }

  .tab_certificates_section {
    display: inline-flex;
    button {
      margin-right: 16px;
      border: solid gray 1px;
      width: 150px;
      justify-content: center;
      &.active {
        border: none;
      }
    }
  }

  padding: 60px 60px;

  .certificate_container_flex {
    margin-bottom: 50px;
    .certificate_desktop {
      display: flex;
      flex-direction: row;
      gap: 100px;
      .certificate_grid {
        /*   display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
        display: flex;
        flex-direction: row;
        // max-width: 300px;
        align-items: center;
        // justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
        column-gap: 20px;
        .certificate_body {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          .image_title {
            gap: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .btn-download {
            background-color: #d4d3ce;
            border-radius: 50%;
            padding: 25px;
            svg {
              padding-top: 5px;
            }
            &:hover {
              background-color: #011754;
              color: white;
            }
          }
          img {
            box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.2);
          }
        }

        .course_name {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 135%;
          /* or 22px */

          display: flex;
          align-items: center;
          text-align: center;

          color: #011754;
        }
      }
    }
    .certificate_mobile {
      display: none;
    }
    .certificate_without_results {
      text-align: center;
      flex-direction: column;
    }
  }
}

@media (max-width: $mobile) {
  .certificates_container {
    padding-top: 100px;
    .certificate_grid {
      // max-width: 200px;
      padding: 10px;
    }
  }
}
