.profile_about_me_container {
  .area-white {
    height: 220px;

    .aboutme_text {
      border: none;
      color: #979a9a;
      line-height: 1.6;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 9; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      &:focus {
        outline: none;
      }
    }
  }

  .descripcion {
    font-weight: bold;
  }
}
