@import '../../../../resources/base';

.home_container {
  .menu_toggle-button {
    display: none;
  }
  .menu {
    position: fixed;
    z-index: 100;
  }
  .outlet {
    width: 100%;
    margin-left: $width-menu;
  }
}

@media screen and (max-width: $mobile) {
  .home_container {
    .menu_toggle-button {
      background-color: #011754;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 65px;
      position: fixed;
      z-index: $z-index-menu;
      .left_menu {
        display: flex;
        align-items: center;
      }
      .right_menu {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-right: 10px;
        .user_options {
          display: flex;
          align-items: center;
          img {
            width: 50px;
            border-radius: 50%;
          }
        }
      }

      .logo {
        height: 50px;
        img {
          height: 100%;
        }
      }

      button {
        display: flex;
        flex-direction: column;
        width: 3rem;
        height: 3rem;
        border: 0;
        background: transparent;
        gap: 0.65rem;
        margin: 10px 12px;
        padding-top: 10px;
      }
      button > div {
        background-color: white;
        height: 2px;
        width: 100%;
        border-radius: 5px;
        transition: all 0.5s;
        transform-origin: left;
      }

      .show_menu div:first-child {
        transform: rotate(45deg);
      }
      .show_menu div:nth-child(2) {
        opacity: 0;
      }
      .show_menu div:last-child {
        transform: rotate(-45deg);
      }
    }
    .menu {
      position: fixed;
      z-index: z-index-menu;
    }

    .hidden_menu {
      left: -90%;
      transition: all 0.5s ease-in-out;
    }

    .show_menu {
      left: 0;
      transition: all 0.5s ease-in-out;
    }
    .outlet {
      width: 100%;
      margin-left: 0;
    }
  }
}
