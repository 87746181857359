@import '../../../../resources/variables';

.course_grabadas_container {
  // height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow-y: auto;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    .title {
      color: $primary-color;
      font-weight: bold;
      margin-top: 6px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .course_grabadas_container {
    height: 100%;
    ul {
      display: flex;
      flex-direction: column;

      .title {
      }
    }
  }
}
