.course_final_container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      .ant-collapse {
        border-radius: 15px !important;
      }

      .ant-collapse-content {
        border-radius: 15px !important;
      }
    }
  }
}
