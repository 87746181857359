@import './variables.scss';

.padding-areas-40 {
  padding: 20px 30px;
}

.area-white {
  padding: 15px 20px;
  border-radius: 25px;
  background-color: white;
}
