.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-50 {
  border-radius: 50%;
}
