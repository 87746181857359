.user_basic_info_container {
  text-align: center;

  .profile_picture {
    height: 100px;
  }

  .user_name {
    margin: 12px auto 5px auto;
    font-weight: 700;
  }

  .user_email {
    margin-top: 0;
    margin-bottom: 8px;
  }
}
