@import '../../../../resources/variables';

.profile_password_container {
  .grid_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: $mobile) {
  .profile_password_container {
    .grid_inputs {
      grid-template-columns: 1fr;
    }
  }
}
