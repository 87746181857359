@import '../../../../resources/base';

.menu_container {
  height: 100vh;
  width: $width-menu;
  margin-bottom: 30px;
  overflow-y: scroll;

  .nav_content {
    margin-top: 40px;
    .nav_title {
      font-size: 22px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .menu_section {
    width: 88%;
    margin: auto;
    margin-bottom: 30px;

    .menu_item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .img_logo {
    margin: 25px;
  }

  .btn_logout {
    background: linear-gradient(90deg, #0e388b, rgba(10, 61, 166, 0.04));
    margin-left: 10px;
  }
}

.menu_container::-webkit-scrollbar {
  width: 3px;
}

.menu_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.menu_container::-webkit-scrollbar-thumb {
  background-color: $secondary-color;
  outline: 1px solid $secondary-color;
}

@media screen and (max-width: $mobile) {
  .menu_container {
    width: 270px;
    position: fixed;
    top: 65px;

    .nav_content {
      margin-top: 30px;
      .my_panel_text {
        font-size: 18px;
      }
      .nav_title {
      }
    }

    .menu_section {
      padding-top: 15px;
      margin-bottom: 40px;
      top: 56px;

      .menu_item {
        .img_logo {
          display: none;
        }
        .bo_button {
          margin-top: 8px !important;
          padding: 5px 20px !important;
        }
      }
    }

    .img_logo {
    }

    .btn_logout {
      padding: 3px 20px;
      font-size: 16px;
    }
  }
}
