@import '../../../../resources/variables.scss';

.course_about_container {
  .course_about {
    color: #011754;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  .border_region {
    height: 10px;
    width: 50px;
    background-color: #ffc200;
    margin-top: 10px;
  }

  .course_videos {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    iframe {
      width: 240px;
      height: 180px;
    }
  }

  .course_description {
    color: #454d6d;
  }

  .title_information {
    display: flex;
    cursor: pointer;
    svg {
      margin-right: 12px;
    }
  }
  thead {
    tr {
      th {
        background-color: lightgray;
      }
    }
  }
}
.modal_body_information {
  max-height: 800px;
  overflow-y: auto;
  margin-top: 25px;
}

@media (max-width: $mobile) {
  .course_about_container {
    .course_videos {
      iframe {
        width: 240px;
        height: 180px;
      }
    }
  }
}
