.boinput_container {
  width: 100%;

  .input_icon {
    display: flex;
    align-items: center;
  }

  .icon {
    margin: auto 10px auto 15px;
  }

  .input {
    background-color: transparent;
    border: 0;
    padding: 12px 8px;
    width: 100%;
  }

  .input:focus {
    border: 0;
    outline: 0;
  }
}

.form_item_input {
  label {
    margin-left: 10px;
    color: #979a9a;
    font-size: 16px;
  }
  .input_icon {
    background-color: white;
    margin-top: 10px;
    border-radius: 20px;
    input {
      padding: 15px 20px 15px 30px;
      font-size: 16px;
      font-weight: bold;
      color: #979a9a;
    }
  }
}
