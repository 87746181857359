.ant-table-column-sorter-down {
  color: #367bf5 !important;
}
.bo_table {
  thead {
    tr {
      th {
        background-color: lightgray;
      }
    }
  }
}
