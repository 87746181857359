.bg-color-primary {
  background-color: #011754;
}

.bg-color-secondary {
  background-color: #ffc200;
}

.bg-color-white {
  background-color: white;
}

.bg-color-areas {
  background-color: #f6f6f5;
}
