.text-color-primary {
  color: #011754;
  a {
    color: #011754;
    text-decoration: none;
  }
}

.text-color-secondary {
  color: #ffc200;
  a {
    color: #ffc200;
    text-decoration: none;
  }
}

.text-color-dark {
  color: black;
  a {
    color: black;
    text-decoration: none;
  }
}

.text-color-light {
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
}

.text-weight-light {
  opacity: 0.7;
}
