@import '../../../../resources/variables';

.live_sessions_container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e5e6;

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .img {
    width: 120px;
  }

  .info {
    margin: 0 30px;
    p {
      margin: 0;
    }
    .title {
      font-weight: bold;
      color: $primary-color;
      font-size: 16px;
    }
  }

  .btn_go {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 0;
    background-color: gray;
    justify-content: center;
    width: 160px;
    &.active {
      background-color: #0575e6;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
    color: white;
    margin-right: 10px;
  }

  .right {
    .desktop_version {
      display: flex;
      align-items: center;
    }
    .mobile_version {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile) {
  .live_sessions_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e5e6;

    .right {
      .desktop_version {
        display: none;
      }
      .mobile_version {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 15px;
    }

    .info {
      margin: 0 20px;
      p {
        margin: 0;
      }
      .title {
        font-weight: bold;
        color: $primary-color;
        font-size: 16px;
      }
    }

    .btn_go {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 20px;
      border-radius: 20px;
      border: 0;
      // background-color: #0575e6;
      color: white;
      margin-right: 20px;
    }
  }
}
