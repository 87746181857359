@import '../../../../resources/variables';

.program_container {
  .program_data_container {
    position: relative;
    width: 380px;
    height: 350px;
    margin-bottom: 20px;

    .program_background {
      position: absolute;
      z-index: -1;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .program_data {
      padding: 15px 17px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .data_header {
        display: grid;
        gap: 15px;
        grid-template-columns: 1fr 3fr 2fr;
        align-items: center;
        .instructor_img {
          img {
            width: 60px;
            border-radius: 50%;
          }
        }

        .sesiones {
          display: flex;
          justify-content: center;
          p {
            margin: 0;
            color: black;
            font-weight: bold;
            padding: 6px 8px;
            font-size: 13px;
            text-align: center;
          }
        }
      }
      .program_info {
        list-style: none;
        margin: 0px;
        padding: 8px;
        p {
          display: flex;
        }
        svg {
          margin-right: 8px;
        }
      }
      p {
        color: white;
        margin: 0;
      }
      .profesor_data {
        font-size: 14px;

        .profesor {
          font-weight: bold;
        }
        .contacto {
        }
      }

      .curso_bg {
        background-color: rgba(180, 191, 221, 0.3);
        padding: 10px 14px;
        display: flex;
        backdrop-filter: blur(36px);
        .curso {
          font-size: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .continuar_curso {
      background-color: white;
      float: right;
      margin-top: 15px;
      border: 0;
      P {
        color: black;
        font-weight: bold;
        font-size: 13px;
        padding: 8px 12px;
      }
    }

    .continuar_curso:hover {
      cursor: pointer;
      background-color: rgba(225, 225, 225, 0.9);
    }
  }
}

@media screen and (max-width: $mobile) {
  .program_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 250px;
    .program_data_container {
      position: relative;
      width: 320px;
      height: 190px;

      .program_background {
        position: absolute;
        width: 100%;
        height: 210px;
        z-index: -1;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .bottom_data {
        margin-bottom: 15px;
      }

      .program_data {
        padding: 15px 20px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        .data_header {
          display: grid;
          gap: 10px;
          grid-template-columns: 1fr 3fr 2fr;
          align-items: center;

          .sesiones {
            display: flex;
            justify-content: center;
            p {
              margin: 0;
              color: black;
              font-weight: bold;
              padding: 6px 8px;
              font-size: 13px;
              text-align: center;
            }
          }
        }
        p {
          color: white;
          margin: 0;
        }
        .profesor_data {
          font-size: 14px;
          .profesor {
            font-weight: bold;
          }
        }

        .curso_bg {
          background-color: rgba(180, 191, 221, 0.3);
          padding: 10px 14px;
          display: flex;
          backdrop-filter: blur(36px);
          .curso {
            font-size: 17px;
          }
        }
      }

      .continuar_curso {
        background-color: white;
        float: right;
        margin-top: 15px;
        border: 0;
        P {
          color: black;
          font-weight: bold;
          font-size: 13px;
          padding: 8px 12px;
        }
      }
    }
  }
}
