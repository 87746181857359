.title_and_text__container {
  .course_about {
    color: #011754;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  .border_region {
    height: 10px;
    width: 50px;
    background-color: #ffc200;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .course_description {
    color: #454d6d;
  }
}
