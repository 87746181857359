@import '../../../../resources/variables';

.course_live_container {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
  }
}

@media screen and (max-width: $mobile) {
  .course_live_container {
    ul {
      grid-template-columns: 1fr;
    }
  }
}
