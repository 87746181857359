@import '../../../../resources/variables';

.profile_account_container {
  .grid_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
    margin-bottom: 10px;
  }
  .btn_reset_password {
    width: 300px;
    border-radius: 20px;
    float: right;
    border: 0;
    padding: 15px 15px;
    font-size: 18px;
    font-weight: bold;
    border: solid 1px black;
    &:hover {
      background-color: white;
    }
  }
}

@media screen and (max-width: $mobile) {
  .profile_account_container {
    .grid_inputs {
      grid-template-columns: 1fr;
    }
  }
}
