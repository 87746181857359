@import '../../../../resources/variables';

.content_panel_container {
  gap: 20px;
  padding: 0 10px;

  .desktop_version {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile_version {
    display: none;
  }

  .left {
    display: flex;
    gap: 20px;

    .icon {
      background-color: #0575e6;
      border-radius: 50%;
      width: 55px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }

      .title {
        color: #0575e6;
        font-weight: bold;
        font-size: 16px;
      }
      .publicado {
        color: $primary-color;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 100px;
    p {
      margin: 0;
    }

    .date {
      color: #9b9d9e;
    }

    .btn {
      &.done {
        background-color: #0575e6;
      }
      &.pending {
        background-color: gray;
      }
      color: white;
      border: 0;
      padding: 10px 25px;
      border-radius: 20px;
      display: flex;
      gap: 15px;
      p {
        margin: 0;
      }
    }
  }
}

.collapse_container {
  .panels {
    .body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .project_description {
        padding-right: 30px;
      }

      .project_description,
      .project_presentation {
        max-width: 50%;
      }
      div {
        flex: 1;
      }
      label {
        font-weight: bold;
      }
      .attachments {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .attachment {
          cursor: pointer;
          gap: 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 8px 10px 6px;
          span {
            flex: 1;
          }
          &:hover {
            background-color: lightgray;
            border-radius: 8px;
          }
        }
      }
    }
    .icons_gap {
      display: flex;
      justify-content: center;
      gap: 7px;
    }

    .drog_and_drop {
      color: #acacac !important;
      font-weight: bold;
    }

    .btn_upload {
      border: 0;
      background-color: #ffc200;
      padding: 6px 18px;
      border-radius: 20px;
      font-weight: bold;
      margin-top: 7px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .content_panel_container {
    // .mobile_version {
    //   display: flex;
    // }

    // .desktop_version {
    //   display: none;
    // }
    .left {
      .icon {
        display: none;
      }

      .info {
        display: flex;
        flex-direction: column;
        p {
        }

        .title {
        }
        .publicado {
        }
      }
    }

    .right {
      p {
      }

      .date {
      }

      .btn {
        p {
        }
      }
    }
  }

  .collapse_container {
    .panels {
      .body {
        display: flex;
        flex-direction: column;
        .project_description,
        .project_presentation {
          max-width: 100%;
        }
      }
      .icons_gap {
      }

      .drog_and_drop {
      }

      .btn_upload {
      }
    }
  }
}
