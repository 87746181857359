@import '../../../../resources/variables';
.bo_subtitle_container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .triangle {
    width: 100%;
    height: 5px;
    border-radius: 100% 0 0 0;
    position: absolute;
    bottom: 0;
  }

  .bo_subtitle {
    border-radius: 15px 15px 0 0;

    p {
      color: white;
      padding: 12px 40px;
      margin: 0;
      font-size: 18px;
      text-align: end;
    }
  }
}

@media screen and (max-width: $mobile) {
  .bo_subtitle_container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    z-index: -1;

    .triangle {
      width: 100%;
      height: 5px;
      border-radius: 100% 0 0 0;
      position: absolute;
      bottom: 0;
    }

    .bo_subtitle {
      border-radius: 15px 15px 0 0;
      width: 80%;

      p {
        color: white;
        padding: 12px 40px;
        margin: 0;
        font-size: 18px;
        text-align: end;
      }
    }
  }

  .swiper {
    position: relative !important;
  }

  .swiper-pagination-horizontal {
    display: flex;
    gap: 30px;
    justify-content: center;
    position: absolute !important;
  }

  .swiper-pagination-bullet {
    border: 2px solid white !important;
    transform: scale(200%);
    box-shadow: 0 0 0 1px #2f59cf;
  }
}
